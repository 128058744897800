<template>
  <div class="down">
    <down-diy />
    <div class="bg use-bg" id="Intermediary">
      <div class="warp">
        <div class="title">
          <div class="warp1-top">
            <div class="title-en" style="text-transform: uppercase">Intermediary version</div>
            <div class="title-zh">
              <span>中介版下载</span>
            </div>
          </div>
        </div>
        <div class="line">
          <div class="card">
            <div class="box">
              <img class="img1" src="https://static.ajl.cn/app/jijia.png" alt="安卓" />
              <div class="img2">
                <img class="img2" src="~@/assets/Android.png" alt="安卓" />
                <span>安卓</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img class="img1" src="https://static.ajl.cn/app/jijia.png" alt="ios" />
              <div class="img2">
                <img class="img2" src="~@/assets/苹果.png" alt="苹果" />
                <span>ios</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img class="img1 down-img" src="~@/assets/down/down.svg" alt="down" width="54" height="54" />
              <span class="down-label">下载</span>
              <div class="img2">
                <img class="img2" src="~@/assets/mac.png" alt="苹果" />
                <span>mac</span>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="box">
              <img class="img1 down-img" src="~@/assets/down/down.svg" alt="down" width="54" height="54" />
              <span class="down-label">下载</span>
              <div class="img2" @click="down">
                <img class="img2" src="~@/assets/微软.png" alt="微软" />
                <span>windows</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg grey-bg" id="Dai">
      <div class="warp-dai scale">
        <div class="div1">
          <div class="warp1-top">
            <div class="title-en" style="text-transform: uppercase">proxy version</div>
            <div class="title-zh">
              <span>代理版下载</span>
            </div>
          </div>
        </div>
        <div class="div2 btn" @click="dai('codeBg')"><img src="../assets/down/an.png" alt="" /><span>安卓</span></div>
        <div class="div3 btn" @click="dai('codeBg')"><img src="../assets/down/ios.png" alt="" /><span>ios</span></div>
        <div class="div4 btn" @click="dai('macBg')"><img src="../assets/down/mac.png" alt="" /><span>mac</span></div>
        <div class="div5 btn" @click="dai('windowsBg')"><img src="../assets/down/window.png" alt="" /><span>windows</span></div>
        <div class="div6">
          <div class="img-bg" :style="`background-image:url(${bgImg})`">
            <img v-if="daiImgC" :key="daiImgC" :src="daiImgC" alt="" data-aos="zoom-in-left" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg use-bg" id="Tuo">
      <div class="warp-tuo scale">
        <div class="div1">
          <div class="warp1-top">
            <div class="title-en" style="text-transform: uppercase">Hosted version</div>
            <div class="title-zh">
              <span>托管版下载</span>
            </div>
          </div>
        </div>
        <div class="div2 btn" @click="tuo('codeBg')"><img src="../assets/down/an.png" alt="" /><span>安卓</span></div>
        <div class="div3 btn" @click="tuo('codeBg')"><img src="../assets/down/ios.png" alt="" /><span>ios</span></div>
        <div class="div4 btn" @click="tuo('macBg')"><img src="../assets/down/mac.png" alt="" /><span>mac</span></div>
        <div class="div5 btn" @click="tuo('windowsBg')"><img src="../assets/down/window.png" alt="" /><span>windows</span></div>
        <div class="div6">
          <div class="img-bg" :style="`background-image:url(${bgImg})`">
            <img v-if="tuoImgC" :key="tuoImgC" :src="tuoImgC" alt="" data-aos="zoom-in-down" />
          </div>
        </div>
      </div>
    </div>
    <div class="bg grey-bg" id="All">
      <div class="warp-all scale">
        <div class="div1">
          <div class="warp1-top">
            <div class="title-en" style="text-transform: uppercase">sale service</div>
            <div class="title-zh">
              <span>全功能版下载</span>
            </div>
          </div>
        </div>
        <div class="div2 btn" @click="all('codeBg')"><img src="../assets/down/an.png" alt="" /><span>安卓</span></div>
        <div class="div3 btn" @click="all('codeBg')"><img src="../assets/down/ios.png" alt="" /><span>ios</span></div>
        <div class="div4 btn" @click="all('macBg')"><img src="../assets/down/mac.png" alt="" /><span>mac</span></div>
        <div class="div5 btn" @click="all('windowsBg')"><img src="../assets/down/window.png" alt="" /><span>windows</span></div>
        <div class="div6">
          <div class="img-bg" :style="`background-image:url(${bgImg})`">
            <img v-if="allImgC" :key="allImgC" :src="allImgC" alt="" data-aos="zoom-in-right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownOne',
  data() {
    return {
      show: false,
      // daiImg: 'https://static.ajl.cn/web/down/w1.jpg',
      daiImgC: '',
      // tuoImg: 'https://static.ajl.cn/web/down/w2.jpg',
      tuoImgC: '',
      // allImg: 'https://static.ajl.cn/web/down/w3.jpg',
      allImgC: '',
      //新增
      bgImg: require('../assets/down/windowsBg.png'),
    };
  },
  methods: {
    down() {
      let ele = document.createElement('a');
      ele.href = 'https://static.ajl.cn/app/jijia.exe';
      ele.click();
    },
    dai(e) {
      // this.daiImg = `https://static.ajl.cn/web/down/${e}.jpg`;
      this.bgImg = require(`../assets/down/${e}.png`);
      this.daiImgC = false;
      if (e.slice(0, 1) === 'c') this.daiImgC = 'https://static.ajl.cn/app/jijia.png';
      if (e.slice(0, 1) === 'c') this.daiImgC = 'https://static.ajl.cn/app/jijia.png';
      if (e === 'windowsBg') this.down();
    },
    tuo(e) {
      // this.tuoImg = `https://static.ajl.cn/web/down/${e}.jpg`;
      this.bgImg = require(`../assets/down/${e}.png`);
      this.tuoImgC = false;
      if (e.slice(0, 1) === 'c') this.tuoImgC = 'https://static.ajl.cn/app/jijia.png';
      if (e.slice(0, 1) === 'c') this.tuoImgC = 'https://static.ajl.cn/app/jijia.png';
      if (e === 'windowsBg') this.down();
    },
    all(e) {
      // this.allImg = `https://static.ajl.cn/web/down/${e}.jpg`;
      this.bgImg = require(`../assets/down/${e}.png`);
      this.allImgC = false;
      if (e.slice(0, 1) === 'c') this.allImgC = 'https://static.ajl.cn/app/jijia.png';
      if (e.slice(0, 1) === 'c') this.allImgC = 'https://static.ajl.cn/app/jijia.png';
      if (e === 'windowsBg') this.down();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.use-bg {
  background-image: url('../assets/use-bg.png');
}
.grey-bg {
  background-image: url('../assets/gray-bg.png');
}
.warp1 {
  &-top {
    text-align: center;
    margin-bottom: 4rem;
    .title-en {
      font-size: 4rem;
      @media (min-width: 800px) {
        font-size: 5rem;
      }
      font-weight: bold;
    }
    .title-zh {
      margin-top: 2rem;
      span {
        font-size: 4rem;
        @media (min-width: 800px) {
          font-size: 4rem;
        }
        font-weight: bold;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background-color: currentColor;
          width: 7.143rem;
          height: 0.57rem;
          top: 50%;
          transform: translateY(-50%);
          left: -8.57rem;
        }
        &:after {
          content: '';
          position: absolute;
          background-color: currentColor;
          width: 7.143rem;
          height: 0.57rem;
          top: 50%;
          transform: translateY(-50%);
          right: -8.57rem;
        }
      }
    }
  }
}
.down {
  background-color: #f2f2f2;
  .gap {
    height: 2rem;
    background-color: white;
  }
}
.warp {
  width: 95vw;
  @media (min-width: 800px) {
    width: 100rem;
  }
  margin: auto;
  @media (min-width: 800px) {
    padding: 16rem 4rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 10rem;
  }
  .companyName {
    text-align: center;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 50rem;
      font-size: 3rem;
      margin: 0;
      outline: 0;
      border: 0;
      line-height: calc(6rem - 2px);
      height: calc(6rem - 2px);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    span {
      display: inline-block;
      line-height: 6rem;
      height: 6rem;
      font-size: 3rem;
      background-color: blue;
      padding: 0 1rem;
    }
  }
}
.line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 800px) {
    justify-content: space-between;
  }
  .card {
    display: grid;
    place-content: center;
    margin: 1rem;
    .box {
      width: 19rem;
      height: 19rem;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: 0.3s;
      padding: 1.43rem;
      overflow: hidden;
      &:hover {
        border-radius: 0.5rem;
        box-shadow: 0 0 5rem grey;
        .img1 {
          opacity: 1;
          margin: 0 auto;
        }
        .down-label {
          opacity: 1;
        }
        .img2 {
          opacity: 0;
        }
      }
      img {
        width: 17.14rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        transition: 0.3s;
        padding: 1.43rem;
        object-fit: contain;
      }
      .down-img {
        top: 3.143rem;
      }
      .img1 {
        opacity: 0;
        margin: 0 auto;
      }
      .down-label {
        opacity: 0;
        width: 17.14rem;
        text-align: center;
        position: absolute;
        color: #f08b1a;
        top: 13.14rem;
      }
      .img2 {
        width: 8.57rem;
        height: 8.57rem;
        opacity: 1;
        padding: 4.29rem 5.71rem;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        span {
          font-size: 2rem;
          position: absolute;
          bottom: 4.29rem;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }
  }
}
.warp-dai {
  width: 95vw;
  transform: scaleY(0.8);
  @media (min-width: 800px) {
    width: 114.29rem;
    padding: 16rem 0;
    transform: unset;
  }
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  .btn {
    padding: 1rem;
    background-color: #cccccc;
    &:hover {
      background-color: #f08b1a;
    }
    text-align: center;
    font-size: 3rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    color: white;
    border-radius: 6px;
    img {
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
    }
  }

  .div1 {
    grid-column: 1/6;
  }
  .div5 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div4 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .div2 {
    grid-area: 5 / 1 / 6 / 2;
  }
  .div6 {
    grid-area: 2 / 2 / 6 / 6;
    height: 45.71rem;
    border-radius: 1rem;
    overflow: hidden;
    background: url('../assets/down/bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .img-bg {
      width: 58.57rem;
      height: 43.71rem;
      background-size: 100% 100%;
      position: relative;
    }
    img {
      position: absolute;
      right: 2.57rem;
      bottom: 7rem;
      width: 19.57rem;
      height: 20.29rem;
    }
  }
}
.warp-tuo {
  width: 95vw;
  padding-left: 1rem;
  @media (min-width: 800px) {
    width: 114.29rem;
    padding: 16rem 0;
  }
  margin: 4rem auto;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  .btn {
    padding: 1rem;
    font-size: 2rem;
    @media (min-width: 800px) {
      padding: 2.6rem;
      font-size: 3rem;
    }
    background-color: #cccccc;
    &:hover {
      background-color: #f08b1a;
    }
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    color: white;
    border-radius: 6px;
    img {
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
    }
  }
  .div1 {
    grid-column: 1/6;
  }
  .div5 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div4 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .div2 {
    grid-area: 2 / 4 / 3 / 5;
  }
  .div6 {
    grid-area: 3 / 1 / 4 / 5;
    //background-color: white;
    height: 45.71rem;
    border-radius: 1rem;
    overflow: hidden;
    background: url('../assets/down/bg.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .img-bg {
      width: 60rem;
      height: 42.86rem;
      background-size: 100% 100%;
      position: relative;
    }
    img {
      position: absolute;
      right: 2.57rem;
      bottom: 7rem;
      width: 19.86rem;
      height: 19.71rem;
    }
  }
}
.warp-all {
  width: 95vw;
  transform: scaleY(0.75);
  @media (min-width: 800px) {
    width: 114.29rem;
    padding: 16rem 0;
    transform: unset;
  }
  margin: 4rem auto 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  .btn {
    padding: 1rem;
    background-color: #cccccc;
    &:hover {
      background-color: #f08b1a;
    }
    text-align: center;
    font-size: 3rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    color: white;
    border-radius: 6px;
    img {
      width: 4rem;
      height: 4rem;
      margin: 0 auto;
    }
  }

  .div1 {
    grid-column: 1/6;
  }
  .div5 {
    grid-area: 2 / 5 / 3 / 6;
  }
  .div3 {
    grid-area: 3 / 5 / 4 / 6;
  }
  .div4 {
    grid-area: 4 / 5 / 5 / 6;
  }
  .div2 {
    grid-area: 5 / 5 / 6 / 6;
  }
  .div6 {
    grid-area: 2 / 1 / 6 / 5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 45.71rem;
    border-radius: 1rem;
    overflow: hidden;
    background: url('../assets/down/bg.png') no-repeat;
    background-size: 100% 100%;
    .img-bg {
      width: 58.57rem;
      height: 42.86rem;
      background-size: 100% 100%;
      position: relative;
    }
    img {
      position: absolute;
      right: 2.57rem;
      bottom: 7.143rem;
      width: 20rem;
      height: 19.43rem;
    }
  }
}
.div6 {
  img {
    box-shadow: 0 0 2rem white;
    border-radius: 0.5rem;
  }
}
</style>
