<template>
  <div class="home-banner">
    <div class="wrap">
      <a-container>
        <div class="title">
          <div class="title-zh">
            <span>纪家</span>
          </div>
          <div class="title-box">
            <div class="title-big">我心中的家</div>
            <div class="title-sub">
              <span>Home</span>
              <span>in</span>
              <span>my</span>
              <span>heart</span>
            </div>
          </div>
        </div>
      </a-container>
      <p class="topInfo">模块设计，灵活性高；不忘初心，成本低；<br />实践调研，贴近用户；分工明确，定制期短；托管服务，售后到位。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NameTop',
};
</script>

<style lang="scss" scoped>
.home-banner {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  background-image: url('https://static.ajl.cn/web/banner.png');
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  .title-zh {
    span {
      &:before {
        width: 7.143rem;
        left: -10rem;
      }
      &:after {
        width: 7.143rem;
        right: -10rem;
      }
    }
  }
  .title-sub {
    span {
      letter-spacing: 0.43rem;
    }
  }
}
</style>
