<template>
  <div class="btn">
    <div class="item top" :class="{ ac: ac === '联系我们' }" @click="about">
      <i class="icon" :class="{ acUs: ac === '联系我们' }" />
      <span>联系我们</span>
    </div>
    <div class="item about" :class="{ ac: ac === '我要试用' }" @click="tryOne">
      <i class="icon" :class="{ acTry: ac === '我要试用' }" />
      <span>我要试用</span>
    </div>
    <div v-show="showTop" class="item goTop" @click="top">
      <i class="icon" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
const about = () => {
  ac.value = '联系我们';
  router.push('/About');
};
const showTop = ref(false);
const ac = ref('');
const top = () => window.scrollTo({ top: 0, behavior: 'smooth' });
document.onscroll = () => (showTop.value = window.pageYOffset > 500);
const tryOne = () => {
  ac.value = '我要试用';
  window.showTry();
};
watch(route, () => window.requestAnimationFrame(() => top()));
</script>

<style lang="scss" scoped>
.btn {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 33rem;
  right: 1rem;
  @media (min-width: 800px) {
    bottom: 3rem;
  }
}
.item {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0.5rem rgba(black, 0.5);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 2rem;
  color: #888888;
  width: 6rem;
  height: 7rem;
  .icon {
    width: 3rem;
    height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.ac {
    background-color: #f08b1a;
    color: white;
  }
  &:hover {
    background-color: #f08b1a;
    color: white;
  }
  span {
    margin-top: 1rem;
    display: inline-block;
    font-size: 1rem;
  }
  @media (min-width: 800px) {
    width: 8rem;
    height: 10rem;
    .icon {
      width: 3.43rem;
      height: 2.29rem;
    }
    span {
      font-size: 1.6rem;
    }
  }
}
.top {
  .icon {
    background-image: url('../assets/contactUs.png');
  }
  &:hover {
    .icon {
      background-image: url('../assets/contactUs-ac.png');
    }
  }
}
.about {
  .icon {
    background-image: url('../assets/try.png');
  }
  &:hover {
    .icon {
      background-image: url('../assets/try-ac.png');
    }
  }
}
.goTop {
  .icon {
    background-image: url('../assets/toTop.png');
  }
  &:hover {
    .icon {
      background-image: url('../assets/toTop-ac.png');
    }
  }
}

.acUs {
  background-image: url('../assets/contactUs-ac.png');
}
.acTry {
  background-image: url('../assets/try-ac.png');
}
</style>
