<template>
  <div class="home-service" id="Service">
    <div class="home-service-top" data-aos="zoom-in">
      <div class="title-en" style="text-transform: uppercase">Join condition</div>
      <div class="title-zh">
        <span>加盟条件</span>
      </div>
    </div>
    <div class="home-service-card">
      <div class="item" data-aos="fade-left">
        <div class="top" style="background-image: linear-gradient(#fae2c8, #ffffff)">
          <img src="../assets/win/condition-base.png" class="icon" />
        </div>
        <div class="title">有经济基础和资源</div>
        <div class="bottom">
          <img class="success" src="../assets/success.png" alt="" />
        </div>
      </div>
      <div class="item" data-aos="fade-left" data-aos-delay="400">
        <div class="top" style="background-image: linear-gradient(#c2f2f1, #ffffff)">
          <img src="../assets/win/condition-industry.png" class="icon" />
        </div>
        <div class="title">热爱并熟悉经纪行业</div>
        <div class="bottom">
          <img class="success" src="../assets/success.png" alt="" />
        </div>
      </div>
      <div class="item" data-aos="fade-left" data-aos-delay="800">
        <div class="top" style="background-image: linear-gradient(#cacffc, #ffffff)">
          <img src="../assets/win/condition-pioneer.png" class="icon" />
        </div>
        <div class="title">不接受三无创业者</div>
        <div class="bottom">
          <img src="../assets/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WinService',
};
</script>

<style lang="scss" scoped>
.home-service {
  background-image: url('../assets/use-bg.png');
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  &-top {
    text-align: center;
    .title-en {
      font-size: 4rem;
      @media (min-width: 800px) {
        font-size: 5rem;
      }
      font-weight: bold;
    }

    .title-zh {
      margin-top: 2rem;
      span {
        font-size: 3rem;
        @media (min-width: 800px) {
          font-size: 4rem;
        }
        font-weight: bold;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background-color: currentColor;
          width: 50px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          left: -60px;
        }
        &:after {
          content: '';
          position: absolute;
          background-color: currentColor;
          width: 50px;
          height: 4px;
          top: 50%;
          transform: translateY(-50%);
          right: -60px;
        }
      }
    }
  }

  &-card {
    padding-top: 10rem;
    display: flex;
    .item {
      margin: 0.5rem;
      width: 30vw;
      @media (min-width: 800px) {
        width: 36rem;
        height: 40rem;
      }
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px;
      border-radius: 10px;
      background: white;
      .top {
        height: 10rem;
        @media (min-width: 800px) {
          height: 20rem;
        }
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .icon {
          width: 10rem;
          height: 6rem;
          margin-bottom: 1rem;
          @media (min-width: 800px) {
            width: 20rem;
            height: 12rem;
            margin-bottom: 2rem;
          }
        }
      }
      .title {
        font-size: 1.5rem;
        @media (min-width: 800px) {
          font-size: 2rem;
        }
        text-align: center;
      }
      .bottom {
        height: 10rem;
        @media (min-width: 800px) {
          height: 20rem;
        }
        background-color: white;
        display: grid;
        place-content: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        img {
          width: 3rem;
          height: 3rem;
          @media (min-width: 800px) {
            width: 6rem;
            height: 6rem;
          }
        }
      }
    }
  }
}
.success {
  width: 3rem;
  height: 2.4rem;
  @media (min-width: 800px) {
    width: 6rem;
    height: 4.8rem;
  }
}
</style>
