<template>
  <div class="a-container">
    <slot></slot>
  </div>
</template>
<style lang="scss">
.a-container {
  color: white;
  position: relative;
  $size: 2.6rem;
  margin-top: 10rem;
  .title {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    &-box {
      display: inline-block;
    }
    &-zh {
      font-size: 3rem;
      @media (min-width: 800px) {
        font-size: 5rem;
      }
      line-height: 1;
      font-family: 'yanzhen', serif;
      display: inline-block;
      span {
        position: relative;
        width: 11.4rem;
        &:before {
          content: '';
          width: inherit;
          height: 0.86rem;
          background-color: white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -13.57rem;
        }
        &:after {
          content: '';
          width: inherit;
          height: 0.86rem;
          background-color: white;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -13.57rem;
        }
      }
    }

    &-big {
      font-size: 4rem;
      position: relative;
      display: inline-block;
      z-index: 1;
      font-family: 'yanzhen', serif;

      &:after {
        content: '';
        position: absolute;
        height: 1.4rem;
        top: 1em;
        left: 0;
        right: 0;
        background-color: #f08b1a;
        z-index: -1;
      }
      @media (min-width: 800px) {
        font-size: 7rem;
        &:after {
          height: 2.86rem;
        }
      }
    }
    &-sub {
      font-size: 1.5rem;
      line-height: 1;
      text-transform: uppercase;
      margin-top: 1.43rem;
      display: flex;
      justify-content: space-between;
      @media (min-width: 800px) {
        font-size: 2rem;
      }
    }
  }
  p {
    text-align: center !important;
  }
}
</style>
