<template>
  <div class="nav-sc"></div>
  <div class="nav" :style="{ backgroundColor }">
    <div class="nav-main">
      <router-link class="nav-main_logo" to="/">
        <img src="@/assets/logo.png" alt="logo" />
      </router-link>
      <div class="nav-main-menu">
        <template v-for="item in list" :key="item.path">
          <router-link class="nav-main-menu_tag" :to="item.path">{{ item.name }}</router-link>
        </template>
      </div>
    </div>
    <div v-if="subShow" class="subMenu" :style="{ backgroundColor }">
      <div class="subMenu-body">
        <template v-for="item in subList" :key="item.path">
          <router-link class="nav-main-menu_tag" :to="item.path">{{ item.name }}</router-link>
        </template>
        <a class="nav-main-menu_tag" href="javascript:void(0);" @click="showTry">我要试用</a>
      </div>
    </div>
  </div>
  <card v-if="showCard" @hide="showCard = false" />
</template>
<script>
export default {
  data() {
    return {
      showCard: false,
      subShow: false,
      backgroundColor: {},
      list: [
        { name: '首页', path: '/home' },
        { name: '纪家SaaS', path: '/product' },
        { name: '关于我们', path: '/About' },
      ],
      subList: [
        { name: '系统介绍', path: 'info' },
        { name: '系统定制', path: 'naming' },
        { name: '下载中心', path: 'down' },
        { name: '招商中心', path: 'win' },
      ],
    };
  },
  watch: {
    $route: {
      handler(v) {
        this.subShow = /product/g.test(v.fullPath);
      },
      immediate: true,
    },
  },
  mounted() {
    window.showTry = this.showTry;
    const el = document.querySelector('.nav-sc');
    document.addEventListener('scroll', () => {
      const { y, height } = el.getBoundingClientRect();
      const op = Math.abs(y / height ?? 1);
      this.backgroundColor = `rgba(0,0,0,${op})`;
    });
  },
  methods: {
    showTry() {
      this.showCard = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-sc {
  //滚动渐变用的
  position: absolute;
  top: 0;
  height: 100vh;
}

.subMenu {
  position: absolute;
  top: 8rem;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
  &-body {
    width: 100%;
    display: flex;
    .nav-main-menu_tag {
      height: 5rem;
      margin: 1rem;
    }
    @media (min-width: 800px) {
      width: 131.4rem;
      .nav-main-menu_tag {
        margin-right: 3rem;
        &:first-child {
          margin-left: 16rem;
        }
      }
    }
  }
}

.nav {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  align-content: center;
  left: 0;
  right: 0;

  &-main {
    width: 131.4rem;
    padding: 0 1rem;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 800px) {
      padding: unset;
    }
    &_logo {
      height: 8rem;

      img {
        height: inherit;
      }
    }

    &-menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      &_tag {
        height: 8rem;
        margin-left: 3rem;
        font-size: 2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        &:after {
          content: '';
          height: 0.3rem;
          position: absolute;
          bottom: 0;
          left: 0.3rem;
          right: 0.3rem;
          border-radius: 0.3rem;
        }
        &.router-link-active {
          &:after {
            background-color: #f08b1a;
          }
        }
        &:hover {
          &:after {
            background-color: #f08b1a;
          }
        }
      }
    }
  }
}
</style>
