<template>
  <div class="footer">
    <div class="top">
      <div class="left">
        <div class="nav mobile">
          <div class="title" @click="go('/home')">首页</div>
          <div class="item" @click="go('/home#Service')">我们的服务</div>
          <div class="item" @click="go('/home#Process')">项目流程</div>
          <div class="item" @click="go('/home#Power')">核心竞争</div>
        </div>
        <div class="nav mobile mobileShow">
          <div class="title" @click="go('/product')">纪家SaaS</div>
          <div class="item" @click="go('/product/naming')">系统定制</div>
          <div class="item" @click="go('/product/down')">下载中心</div>
          <div class="item" @click="go('/product/win')">招商中心</div>
          <div class="item" @click="tryOne">我要试用</div>
        </div>
        <div class="nav">
          <div class="title" @click="go('/product')">纪家SaaS</div>
          <div class="item" @click="go('/product#Top')">系统介绍</div>
          <div class="item" @click="go('/product#Two')">服务内容</div>
          <div class="item" @click="go('/product#Use')">系统用途</div>
          <div class="item" @click="go('/product#Dict')">系统特色</div>
          <div class="item" @click="go('/product#Trip')">人员管理</div>
          <div class="item" @click="go('/product#Bottom')">楼盘字典</div>
        </div>
        <div class="nav">
          <div class="title">&emsp;</div>
          <div class="item" @click="go('/product/naming')">系统定制</div>
          <div class="item" @click="go('/product/naming#One')">定制冠名</div>
          <div class="item" @click="go('/product/naming#Power')">四大特权</div>
          <div class="item" @click="go('/product/naming#TimeIng')">VIP服务</div>
        </div>
        <div class="nav">
          <div class="title">&emsp;</div>
          <div class="item" @click="go('/product/down')">下载中心</div>
          <div class="item" @click="go('/product/down#Diy')">定制版下载</div>
          <div class="item" @click="go('/product/down#Intermediary')">中介版下载</div>
          <div class="item" @click="go('/product/down#Dai')">代理版下载</div>
          <div class="item" @click="go('/product/down#Tuo')">托管版下载</div>
          <div class="item" @click="go('/product/down#All')">全端版下载</div>
        </div>
        <div class="nav">
          <div class="title">&emsp;</div>
          <div class="item" @click="go('/product/win')">招商中心</div>
          <div class="item" @click="go('/product/win#Power')">行业分析</div>
          <div class="item" @click="go('/product/win#Service')">加盟条件</div>
          <div class="item" @click="go('/product/win#After')">售后保障</div>
        </div>
        <div class="nav">
          <div class="title">&emsp;</div>
          <div class="item" @click="tryOne">我要试用</div>
        </div>
        <div class="nav mobile">
          <div class="title" @click="go('/About')">关于我们</div>
          <div class="item" @click="go('/About#Top')">公司简介</div>
          <div class="item" @click="go('/About#ContactUs')">联系我们</div>
        </div>
      </div>
      <div class="right">
        <div class="title">400-1313-113</div>
        <div class="work">周一至周日 24小时服务</div>
        <div class="chat">
          <div class="chat-item">
            <img class="icon" src="~@/assets/qq.png" alt="qq" />
            <img class="code" src="~@/assets/qqcode.png" alt="qq" />
          </div>
          <div class="chat-item">
            <img class="icon" src="~@/assets/wk.png" alt="wk" />
            <img class="code" src="~@/assets/wkcode.png" alt="wk" />
          </div>
          <div class="chat-item">
            <img class="icon" src="~@/assets/wx.png" alt="wx" />
            <img class="code" src="~@/assets/wxcode.jpg" alt="wx" />
          </div>
        </div>
      </div>
    </div>
    <div class="center"></div>
    <div class="bottom">
      <div class="logo"></div>
      <div class="copyright">
        <a href="https://beian.miit.gov.cn">苏ICP备2020052756号</a>
        ©{{ new Date().getFullYear() }} 苏州爱接力网络科技有限公司. help by mail@ajl.cn
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
const go = (e) => {
  router.push(e);
};
const tryOne = () => window.showTry();
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #484848;
  padding: 4rem 0 2rem;
  .top {
    width: 90vw;
    display: flex;
    position: relative;
    @media (min-width: 800px) {
      width: 131.43rem;
    }
    .left {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .nav {
        display: flex;
        flex-direction: column;
        color: white;
        &:not(.mobile) {
          display: none;
        }
        @media (min-width: 800px) {
          &:not(.mobile) {
            display: flex;
          }
        }
        .title {
          font-size: 2rem;
          margin-bottom: 1rem;
          cursor: pointer;
        }

        .item {
          font-size: 1.6rem;
          margin-bottom: 1rem;
          cursor: pointer;
          opacity: 0.5;
        }
      }
      @media (min-width: 800px) {
        .mobileShow {
          display: none;
        }
      }
    }

    .right {
      text-align: right;
      flex: 1;
      display: flex;
      flex-direction: column;
      color: white;

      .title {
        font-size: 2rem;
        @media (min-width: 800px) {
          font-size: 4rem;
        }
      }

      .work {
        font-size: 1.6rem;
        padding-top: 1rem;
        @media (min-width: 800px) {
          padding-top: 2rem;
          font-size: 2rem;
        }
      }

      .chat {
        padding-top: 3rem;
        display: flex;
        justify-content: flex-end;

        &-item {
          position: relative;

          .icon {
            box-sizing: content-box;
            width: 3em;
            height: 3em;
            padding: 0 1em;

            &:hover {
              cursor: pointer;

              & + .code {
                transform: scale(1);
              }
            }
          }

          .code {
            width: 20rem;
            height: 20rem;
            padding: 1em;
            object-fit: contain;
            background-color: white;
            box-shadow: 0 0 2em black;
            position: absolute;
            bottom: 5em;
            left: calc(50% - 10rem);
            border-radius: 1em;
            transform: scale(0);
          }
        }
      }
    }
  }

  .center {
    height: 1px;
    width: 120rem;
    background-color: rgba(#ebe0ce, 0.1);
    margin: 3rem;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 800px) {
      width: 130.43rem;
    }
    .logo {
      background-image: url('~@/assets/logo.png');
      width: 20rem;
      height: 5rem;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .copyright {
      font-size: 2rem;
      color: rgba(white, 0.5);
      a {
        color: rgba(white, 0.5);
      }
    }
  }
}
</style>
