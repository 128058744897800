<template>
  <div class="modal-box" data-aos="fade-in" @click.self="hide">
    <div class="we-card-box">
      <div class="close" @click="hide">×</div>
      <div class="title">我要试用</div>
      <div class="line">
        <input v-model="obj.name" type="text" placeholder="您的姓名" />
        <input v-model="obj.tel" type="text" placeholder="您的联系方式" />
      </div>
      <div class="line">
        <input v-model="obj.mail" type="text" placeholder="您的邮箱" />
        <input v-model="obj.company" type="text" placeholder="您的公司" />
      </div>
      <textarea v-model="obj.note" placeholder="请输入您的需求内容"></textarea>
      <div v-if="btn" class="btn" @click="send">提交信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  emits: ['hide'],
  data() {
    return {
      btn: true,
      obj: {},
    };
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden';
  },
  beforeUnmount() {
    document.documentElement.style.overflow = '';
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
    async send() {
      if (!this.obj.tel) return alert('请填写您的联系方式，谢谢');
      const res = await fetch('https://dev-api.ajl.cn/v2/user/add-apply', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.obj),
      });
      const { state, msg } = await res.json();
      if (state === 1) {
        await alert('稍后有人与您联系');
        this.hide();
      } else {
        await alert(msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-box {
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.we-card-box {
  width: 95vw;
  @media (min-width: 800px) {
    width: 114rem;
  }
  height: 88rem;
  @media (min-width: 800px) {
    height: 70rem;
  }
  background-color: white;
  box-shadow: 0 1rem 3rem 0.5rem rgba(118, 118, 118, 0.13);
  text-align: center;
  border-radius: 1rem;
  position: relative;
  * {
    box-sizing: border-box;
  }
  .close {
    position: absolute;
    font-size: 4rem;
    top: 2rem;
    right: 4rem;
    cursor: pointer;
  }

  .title {
    text-align: center;
    color: #332e29;
    font-size: 4rem;
    font-weight: bold;
    padding: 5rem;
  }

  .line {
    text-align: center;

    input {
      font-size: 2rem;
      width: 45.7rem;
      height: 6.6rem;
      margin: 2rem;
      padding: 0.5rem 2rem;
      text-shadow: none;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  textarea {
    font-size: 2rem;
    width: 45.7rem;
    @media (min-width: 800px) {
      width: 95.7rem;
    }
    height: 14rem;
    margin: 2rem;
    text-shadow: none;
    resize: none;
    outline: none;
    border: 1px solid #ddd;
    padding: 2rem;
    border-radius: 4px;
    &:focus {
      border: 2px solid black;
      border-radius: 4px;
    }
  }

  .btn {
    margin: auto;
    width: 33rem;
    height: 6rem;
    font-size: 2rem;
    color: white;
    background-color: #f08b1a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
  }
}
</style>
