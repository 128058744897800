import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';

const routes = [
  {
    path: '/',
    alias: ['/Home'],
    name: 'home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/Product',
    name: 'product',
    redirect: '/product/info',
    component: () => import('@/views/Product'),
    children: [
      {
        path: 'Info',
        name: 'info',
        component: () => import('@/views/Product/Info'),
      },
      {
        path: 'Naming',
        name: 'naming',
        component: () => import('@/views/Product/Naming'),
      },
      {
        path: 'Down',
        name: 'down',
        component: () => import('@/views/Product/Down'),
      },
      {
        path: 'Win',
        name: 'win',
        component: () => import('@/views/Product/Win'),
      },
    ],
  },

  {
    path: '/About',
    name: 'about',
    component: () => import('@/views/About'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    setTimeout(async () => {
      if (to.hash) {
        await nextTick();
        document.querySelector(to.hash)?.scrollIntoView({ behavior: 'smooth' });
      }
    });
  },
});

export default router;
