<template>
  <div class="home-banner">
    <div class="wrap">
      <a-container>
        <div class="title">
          <div class="title-zh">
            <span>服务由此开始</span>
          </div>
          <div class="title-big">为你经纪事业添油助力</div>
          <div class="title-sub">
            <span>So</span>
            <span>the</span>
            <span>service</span>
            <span>starts</span>
            <span>help</span>
            <span>your</span>
            <span>career</span>
          </div>
        </div>
      </a-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NameTop',
};
</script>

<style lang="scss" scoped>
.home-banner {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  background-image: url('https://static.ajl.cn/web/banner.png');
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  .title-sub {
    span {
      letter-spacing: 0.43rem;
    }
  }
}
</style>
