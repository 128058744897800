<template>
  <div class="home-service">
    <div class="home-service-top">
      <div class="title-en">SELECTED CASES</div>
      <div class="title-zh">
        <span>代表案例</span>
      </div>
    </div>
    <div class="home-service-card">
      <div class="item">
        <div class="title">苏州铁纪找房房地产有限公司</div>
        <div class="cid"></div>
      </div>
      <div class="item">
        <div class="title">企业名称企业名称</div>
        <div class="cid"></div>
      </div>
      <div class="item">
        <div class="title">企业名称企业名称</div>
        <div class="cid"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCase',
};
</script>

<style lang="scss" scoped>
.home-service {
  background-color: #282828;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-top {
    text-align: center;

    .title-en {
      font-size: 4rem;
      font-weight: bold;
      color: white;
    }

    .title-zh {
      margin-top: 2rem;
      margin-bottom: 4.5rem;

      span {
        font-size: 2rem;
        font-weight: 400;
        color: #808080;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -1em;
          left: 1em;
          right: 1em;
          height: 0.286rem;
          background-color: #808080;
        }
      }
    }
  }

  &-card {
    padding-top: 8rem;
    display: flex;
    justify-content: center;

    .item {
      height: 34rem;
      width: 39rem;
      background-color: #4a4a4a;
      position: relative;

      &:hover {
        .cid {
          &:after {
            display: block;
          }

          &:before {
            display: block;
          }
        }
      }

      &:nth-child(2) {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      .title {
        text-align: center;
        font-size: 2rem;
        color: #808080;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4rem;
      }

      .cid {
        position: absolute;
        bottom: -18.2rem;
        left: 0;
        right: 0;
        border: 1px solid #4a4a4a;

        &:nth-child(2) {
          left: -1.5rem;
          right: -1.5rem;
        }

        &:after {
          display: none;
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 2.8rem;
          border: 1px solid white;
          margin: auto;
          transform: translateY(-50%);
        }

        &:before {
          display: none;
          position: absolute;
          content: '';
          left: 0;
          right: 0;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 2.8rem;
          background-color: white;
          margin: auto;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
