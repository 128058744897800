<template>
  <div class="one" id="One">
    <div class="title">软件冠名后</div>
    <div class="title">这就是你公司自己开发的中介系统</div>
    <div class="sub">
      纪家的软件冠名与你去找软件定制公司开发一样，都能展现你公司的实力与品牌影响力。 不论是桌面图标、移动端战术，甚至是分享链接都将是你公司的形象。对内，无论是给你的 直营店或者加盟店使用，都能满足他们对公司实力的期待。对外，每一次房客源分享都是向客户
      展示你公司的强大实力
    </div>
    <div class="btn">
      <div class="item" :class="{ active: show }" @click="show = true">冠名前</div>
      <div class="item" :class="{ active: !show }" @click="show = false">冠名后</div>
    </div>
    <div v-if="show" class="img">
      <img src="https://static.ajl.cn/web/pc1.png" />
    </div>
    <div v-else class="img">
      <img src="https://static.ajl.cn/web/pc2.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'One',
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.one {
  background-image: url('../assets/gray-bg.png');
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem 0;
  .title {
    font-size: 3rem;
    width: 95vw;
    @media (min-width: 800px) {
      width: 85rem;
      font-size: 4rem;
    }
    text-align: center;
    font-weight: bold;
  }

  .sub {
    padding-top: 2rem;
    width: 95vw;
    text-align: justify;
    @media (min-width: 800px) {
      width: 92.86rem;
    }
    font-size: 2rem;
    color: #a6a6a6;
  }

  .img {
    padding: 2rem;
    img {
      width: 100vw;
      object-fit: contain;
      @media (min-width: 800px) {
        width: 80rem;
      }
    }
  }

  .btn {
    margin: 8rem 0 4rem 0;
    display: flex;
    background: #ffffff;
    color: #a6a6a6;
    border-radius: 2.86rem;
    font-size: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px;
    .item {
      width: 17.14rem;
      height: 5.71rem;
      line-height: 5.71rem;
      text-align: center;
      &:hover {
        color: white;
        background-color: #ef8b19;
        border-radius: 20px;
      }
      &.active {
        color: white;
        background-color: #ef8b19;
        border-radius: 20px;
        border-radius: 2.86rem;
      }
    }
  }
}
</style>
