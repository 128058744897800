<template>
  <down-one v-if="isQf" />
  <template v-else>
    <nav-bar />
    <router-view />
    <nav-footer />
    <go-top />
  </template>
</template>
<script setup>
import NavBar from '@/components/nav-bar';
import GoTop from '@/components/goTop';
import NavFooter from '@/components/nav-footer';
const isQf = location.host.match('qfbj.cn');
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}

* {
  line-height: 1.5;
  margin: 0;
  padding: 0;
  @media (max-width: 800px) {
    cursor: default !important;
  }
}

html,
body {
  font-size: 7px;
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

@font-face {
  font-family: 'yanzhen';
  src: url('~@/assets/font/iconfont.ttf');
}
</style>
