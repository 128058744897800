import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  offset: 200,
  duration: 300,
  easing: 'ease-in-sine',
  delay: 0,
});
window.app = createApp(App);
app.http = function (url, obj) {
  return new Promise((resolve, reject) => {
    fetch('https://' + url, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        if (res.status === 200) return res.json();
        res.json().then((e) => alert(e.msg));
        reject();
      })
      .then((res) => resolve(res));
  });
};
app.use(router);
const requireComponent = require.context('@/components/', false, /\w+\.(vue|js)$/);
requireComponent.keys().forEach((fileName) => {
  const config = requireComponent(fileName);
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
  app.component(componentName, config.default || config);
});
app.mount('#app');
