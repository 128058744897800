<template>
  <div class="NamePrower" id="Power">
    <div class="wrap">
      <div class="title">
        四大特权<br />
        只为你的公司而定制
      </div>
      <div class="line">
        <div class="line-arrow" @click="ac += 0.5">&lt;</div>
        <div class="line-body">
          <div class="line-body-item" v-for="(a, i) in list" :key="i" :style="{ left: (ac + i) * 50 + 'rem' }">
            <div class="line-body-item-content">
              <div class="label">{{ a.title }}</div>
              <div class="intro">{{ a.intro }}</div>
              <img :src="a.icon" alt="" :class="{ bt: i === 1 || i === 2, img4: i === 3 }" />
            </div>
          </div>
        </div>
        <div class="line-arrow" @click="ac -= 0.5">&gt;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NamePrower',
  data() {
    return {
      ac: 0,
      list: [
        {
          title: '电脑桌面软件',
          intro: '纪家为你的冠名而精心设计，当你公司的LOGO与色彩出现在ERP的图标时，不仅能让团队成员快速找到，更能在日渐使用中感受到系统所带来的的工作效率的改变与业绩提升，从而对公司产生更高的荣誉感与归属感。',
          icon: require('../assets/pc-table.png'),
        },
        {
          title: '手机端',
          intro: '与自主研发不同，纪家不单单为你带来电脑端产品，更为你带来手机移动客户端。不同于大众版本，你的移动端APP从名称到多个细节的展示，都将留下你公司的印记，从而带给员工更强的自信与动力。',
          icon: require('../assets/mobile-table.png'),
        },
        {
          title: '分享链接',
          intro: '微信营销是公司成员每天都在做的事。纪家针对冠名用户特殊定制了与众不同的分享链接，确保你公司每一次营销都能让客户在众多营销分享中选中你们并且产生信任，从而为你公司带来业绩的突破。',
          icon: require('../assets/link-table.png'),
        },
        { title: '登录页', intro: '这个登录页面只为你公司设计，你可以把公司的主题色彩或有重要意义的图片以炫彩、热情、华美的方式呈现在登录页面上。带来惊艳视觉的同时激励员工去拼搏、去创造。', icon: 'https://static.ajl.cn/web/pcshare.png' },
      ],
    };
  },
  watch: {
    ac(v) {
      if (v < -1.5) this.ac = -1.5;
      if (v > 0) this.ac = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.line {
  width: 131.43rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &-arrow {
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 1.43rem;
    font-size: 4.29rem;
    &:first-of-type {
      left: 0;
      position: relative;
      user-select: none;
      &:after {
        content: '';
        position: absolute;
        height: 71.429rem;
        top: -28.57rem;
        width: 14.29rem;
        left: 5.71rem;
        background-image: linear-gradient(to right, white, transparent);
      }
    }
    &:last-of-type {
      right: 0;
      position: relative;
      user-select: none;
      &:after {
        content: '';
        position: absolute;
        height: 71.429rem;
        top: -28.57rem;
        width: 14.29rem;
        right: 5.71rem;
        background-image: linear-gradient(to left, white, transparent);
      }
    }
  }
  &-body {
    @media (min-width: 800px) {
      flex: 1;
      position: relative;
      height: 57.14rem;
      overflow: hidden;
      padding: 4rem 0;
    }
    &-item {
      width: 95vw;
      margin-bottom: 4rem;
      @media (min-width: 800px) {
        position: absolute;
        top: 4rem;
        width: 50rem;
        height: inherit;
        padding: 0 1.43rem;
        box-sizing: border-box;
      }
      &-content {
        background: white;
        border-radius: 18px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        .label {
          width: 41.43rem;
          font-size: 2.5rem;
          text-align: left;
          padding: 4rem 0;
        }
        .intro {
          width: 41.43rem;
          font-size: 1.5rem;
          color: #a6a6a6;
          line-height: 1.8;
          margin-bottom: 4rem;
        }
        img {
          width: 31.43rem;
          height: 24.3rem;
        }
        .bt {
          width: 30rem;
          height: 29.43rem;
        }
        .img4 {
          width: 37.14rem;
        }
      }
    }
  }
}
.NamePrower {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../assets/use-bg.png');
  background-attachment: fixed;
  background-size: cover;
  .wrap {
    width: 131.43rem;
  }
  .title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: bold;
  }
}
</style>
