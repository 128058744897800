<template>
  <div class="after" id="After">
    <div class="wrap">
      <div class="wrap-top">
        <div class="title-en" style="text-transform: uppercase">sale service</div>
        <div class="title-zh">
          <span>— 售后保障 —</span>
        </div>
      </div>
      <div class="wrap-content">
        <div class="item" style="background-color: #f08b1a">
          <img class="icon" src="../assets/win/guarantee1.png" alt="" />
          <div class="label">保障一</div>
          <div class="intro">7*24 急速响应，售前售后全方位支持</div>
        </div>
        <div class="item" style="background-color: #15bd6c">
          <img class="icon" src="../assets/win/guarantee2.png" alt="" />
          <div class="label">保障二</div>
          <div class="intro">市场物料支持，线下线上多渠道推广</div>
        </div>
        <div class="item" style="background-color: #5565fa">
          <img class="icon" src="../assets/win/guarantee3.png" alt="" />
          <div class="label">保障三</div>
          <div class="intro">专业技术维护支持，保证产品高可用性</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WinAfter',
};
</script>

<style lang="scss" scoped>
.after {
  background-image: url('https://static.ajl.cn/suzhou.jpg');
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}
.wrap {
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrap {
  &-top {
    color: white;
    padding: 4rem 8rem;
    margin: 0 auto;
    width: 36rem;
    border: 2px solid #ffffff;
    text-align: center;
    border-radius: 10px;
    .title-en {
      font-size: 3rem;
      @media (min-width: 800px) {
        font-size: 4rem;
      }
      font-weight: bold;
    }
    .title-zh {
      span {
        font-size: 3rem;
        @media (min-width: 800px) {
          font-size: 4rem;
        }
        font-weight: bold;
      }
    }
  }
  &-content {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 8rem;
    .item {
      padding: 1rem;
      @media (min-width: 800px) {
        height: 45rem;
        width: 37rem;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      .icon {
        width: 5rem;
        height: 5rem;
        @media (min-width: 800px) {
          width: 11.43rem;
          height: 11.43rem;
        }
      }
      .label {
        font-size: 3rem;
        padding: 4rem 0;
      }
      .intro {
        font-size: 1.5rem;
        @media (min-width: 800px) {
          font-size: 2rem;
        }
      }
    }
  }
}
</style>
