<template>
  <div class="home-banner">
    <div class="wrap">
      <a-container>
        <div class="title">
          <div class="title-zh">
            <span>招商中心</span>
          </div>
          <div class="box">
            <div class="title-big">共创房地产经纪生态平台</div>
            <div class="title-sub" data-aos="fade-down" style="text-transform: uppercase">
              <span>jointly</span>
              <span>create</span>
              <span>an</span>
              <span>ecological</span>
              <span>platform</span>
              <span>for</span>
              <span>real</span>
              <span>estate</span>
              <span>brokerage</span>
            </div>
          </div>
        </div>
      </a-container>
      <p class="topInfo">
        俗话：“有房才是家”充分说明房子是生活的必须，有人，就有需求，有需求，就有市场！<br />房地产交易早已进入万亿时代，房地产从业人员已超过两百万，这两年虽然受疫情影响，但丝毫没减退其发展的热情，在持续上升。<br />随着科技的发展，房地产经纪交易也必将越发正规透明，当然不法分子的犯罪手段也越发高明，对于这种多则几千万，少则百十万的交易越发谨慎，所以交易手续也逐步严谨而繁琐，从单凭一纸契约交易过户，到今天30多道手续，越发对房地产经纪人员专业要求越来越高。
        <br />不仅对其专业要求过硬，对其服务也是要求越来越高，以前懂户型、懂装修，懂风水、会算税费、会讲房、会议价已经不能满足了，还要懂金融、懂法律、懂教育、懂财务等等，这时就更需要一套专业的系统支撑。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeBanner',
};
</script>

<style lang="scss" scoped>
.home-banner {
  background-image: url('https://static.ajl.cn/web/banner.png');
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  .wrap {
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
