<template>
  <div class="timeing">
    <div class="title">更具时代感的功能和设计，为你的公司单独打造</div>
    <div class="sub">好用的系统才能为公司带来更大的价值，纪家为你精心打磨每一个细节，使它更符合你们的使用习惯, 也更易于上手，从而使团队成员确认这就是公司为他们而单独打造的系统。</div>
    <img src="../assets/name/mac.png" alt="timeing" />
  </div>
  <div class="name-card" id="TimeIng">
    <div class="wrap">
      <div class="card">
        <img src="../assets/name/VIP.png" />
        <div class="title">VIP 服务</div>
        <div class="sub">
          纪家为每位定制用户提供VIP服务<br />
          专业客服团队7 x 24小时待命<br />
          技术团队持续不断对系统进行常规升级和新功能更新<br />
          以及提供技术支持让你毫无后顾之忧
        </div>
      </div>
    </div>
  </div>
  <div class="last">
    <div class="title">以不到软件定制公司十分之一的价格拥有一个媲美链家的定制软件</div>
    <div class="line">
      <span>现价 RMB XXXX元</span>
      <span>原价 RMB XXXX元</span>
    </div>
    <div class="text">
      普通的软件定制公司服务太多行业，因此不能对房地产行业进行深入的了解与开发 导致对产品需求把握不准、重做率高、价格高昂、后期维护差，升级还要另外收费 而选择我们的软件冠名定制，你的公司就能以不到软件定制公司十分之一的价格 获得一个房产中介管理软件
    </div>
    <div class="img-box">
      <img src="../assets/name/order.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timeing',
};
</script>

<style lang="scss" scoped>
.timeing {
  background-image: url('../assets/gray-bg.png');
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  .title {
    font-size: 3rem;
    @media (min-width: 800px) {
      font-size: 4rem;
    }
    font-weight: bold;
    margin: 3rem;
    text-align: center;
  }

  .sub {
    font-size: 2rem;
    text-align: justify;
    color: #a6a6a6;
    width: 95vw;
    @media (min-width: 800px) {
      width: 92.86rem;
    }
  }

  img {
    width: 36rem;
    height: 42rem;
    object-fit: contain;
    @media (min-width: 800px) {
      width: 43rem;
      height: 52rem;
    }
    margin: 6rem;
  }
}

.name-card {
  background-image: url('https://ajl-static.oss-cn-hangzhou.aliyuncs.com/ajl-website/2021-02-05%2010%3A49/many-people.png');
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  .wrap {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .card {
    text-align: center;
    color: white;
    padding: 5rem;
    @media (min-width: 800px) {
      padding: 7rem;
    }
    border: 2px solid #ffffff;
    border-radius: 20px;
    background-color: rgba(225, 225, 225, 0.15);
    img {
      width: 11rem;
      height: 7rem;
    }
    .title {
      font-size: 4rem;
      font-weight: bold;
      margin: 2rem 0;
    }
    .sub {
      font-size: 2rem;
    }
  }
}

.last {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/use-bg.png');
  background-size: cover;
  min-height: 100vh;
  .title {
    font-size: 3rem;
    font-weight: bold;
    width: 95vw;
    @media (min-width: 800px) {
      width: 67.14rem;
      font-size: 4rem;
    }
    text-align: center;
  }

  .line {
    padding: 4rem 0;
    span {
      display: inline-block;
      padding: 3rem;
      font-size: 2rem;
      color: #a6a6a6;
      padding: 1rem 3rem;
      border: 1px solid #a6a6a6;
      border-radius: 20px;
      &:nth-child(1) {
        color: #f08b1a;
        border-color: #f08b1a;
        margin-right: 4rem;
      }
      &:nth-child(2) {
        text-decoration: line-through;
      }
    }
  }
  .img-box {
    display: inline-block;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      height: 16rem;
      z-index: 0;
      bottom: 10em;
      background-color: #f08b1a;
    }
  }
  img {
    width: 100vw;
    object-fit: contain;
    @media (min-width: 800px) {
      width: 103.29rem;
      height: 59.71rem;
    }

    margin: 2rem;
    position: relative;
    z-index: 1;
  }

  .text {
    width: 95vw;
    @media (min-width: 800px) {
      width: 105.71rem;
    }
    color: #a6a6a6;
    font-size: 2rem;
    text-indent: 2em;
  }
}
</style>
