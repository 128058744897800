<template>
  <div class="diy" id="Diy">
    <div class="title">
      <div class="warp1-top">
        <div class="title-en" style="text-transform: uppercase">Customized vip</div>
        <div class="title-zh">
          <span v-if="info.apk"> {{ name }} 定制版下载</span>
          <span v-else>定制版下载</span>
        </div>
      </div>
    </div>
    <div v-if="!show" class="companyName">
      <input v-model.trim="name" list="cars" type="text" placeholder="请输入公司全称" @keydown.enter="getUrl" @input="getNames" />
      <datalist id="cars">
        <option v-for="a in names" :key="a" :value="a"></option>
      </datalist>
      <span class="btn" @click="getUrl()">获取地址</span>
    </div>
    <template v-if="show">
      <div class="line">
        <div class="card">
          <div class="box" @click="down(info.apk.url)">
            <img class="img1" :src="info.apk.code" alt="安卓" />
            <div class="img2">
              <img class="img2" src="~@/assets/鸿蒙.png" alt="安卓" />
              <span>安卓</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="box" @click="down(info.ios.url)">
            <img class="img1" :src="info.ios.code" alt="ios" />
            <div class="img2">
              <img class="img2" src="~@/assets/苹果.png" alt="苹果" />
              <span>ios</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="box" @click="down(info.mac.url)">
            <img class="img1" :src="info.mac.code" alt="down" />
            <div class="img2">
              <img class="img2" src="~@/assets/mac.png" alt="苹果" />
              <span>mac</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="box" @click="down(info.win.url)">
            <img class="img1" src="~@/assets/down.svg" alt="down" />
            <div class="img2">
              <img class="img2" src="~@/assets/微软.png" alt="微软" />
              <span>windows</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import QRCode from 'qrcode';
export default {
  data() {
    return {
      show: false,
      name: '',
      names: [],
      info: {},
    };
  },
  methods: {
    async code(url) {
      return QRCode.toDataURL(url, {
        margin: 2,
        color: { light: '#f0f0f000' },
      });
    },
    async getNames() {
      if (!this.name) return;
      const { list } = await app.http('svc.ajl.cn/down/names', { name: this.name });
      this.names = list;
    },
    async getUrl() {
      if (!this.name) return alert('请输入贵公司名称');
      const { info } = await app.http('svc.ajl.cn/down', { name: this.name });
      for (let a in info) {
        const origin = info[a];
        info[a] = {
          url: origin,
          code: origin ? await this.code(origin) : require('@/assets/stop.svg'),
        };
      }
      this.info = info;
      this.show = true;
    },
    down(url) {
      if (!url) return;
      let ele = document.createElement('a');
      ele.href = url;
      ele.target = '_blank';
      ele.download = '房源管理系统';
      ele.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.diy {
  background-image: url('../assets/gray-bg.png');
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.warp1 {
  &-top {
    text-align: center;
    margin-bottom: 4rem;
    .title-en {
      font-size: 4rem;

      @media (min-width: 800px) {
        font-size: 5rem;
      }
      font-weight: bold;
    }
    .title-zh {
      margin-top: 2rem;
      span {
        font-size: 3rem;

        @media (min-width: 800px) {
          font-size: 4rem;
        }
        font-weight: bold;
        position: relative;
        @media (min-width: 800px) {
          &:before {
            content: '';
            position: absolute;
            background-color: currentColor;
            width: 7.14rem;
            height: 0.57rem;
            top: 50%;
            transform: translateY(-50%);
            left: -8.57rem;
          }
          &:after {
            content: '';
            position: absolute;
            background-color: currentColor;
            width: 7.14rem;
            height: 0.57rem;
            top: 50%;
            transform: translateY(-50%);
            right: -8.57rem;
          }
        }
      }
    }
  }
}
.title {
  font-size: 4rem;
  text-align: center;
  margin-bottom: 10rem;
}
.companyName {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 35rem;
    @media (min-width: 800px) {
      width: 50rem;
    }
    font-size: 3rem;
    margin: 0;
    outline: 0;
    border: 0;
    line-height: calc(6rem - 2px);
    height: calc(6rem - 2px);
    padding-left: 1rem;
    padding-right: 1rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  span {
    display: inline-block;
    line-height: 6rem;
    height: 6rem;
    font-size: 3rem;
    background-color: #f08b1a;
    padding: 0 1rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.line {
  display: flex;
  justify-content: center;
  @media (min-width: 800px) {
    justify-content: space-between;
  }
  flex-wrap: wrap;
  .card {
    display: grid;
    place-content: center;
    margin: 1rem;
    .box {
      width: 17.1rem;
      height: 17.1rem;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 50%;
      transition: 0.3s;
      padding: 1.43rem;
      overflow: hidden;
      &:hover {
        border-radius: 0.5rem;
        box-shadow: 0 0 5rem grey;
        .img1 {
          opacity: 1;
        }
        .img2 {
          opacity: 0;
        }
      }
      img {
        width: 17.1rem;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        transition: 0.3s;
        padding: 1.43rem;
        object-fit: contain;
      }
      .img1 {
        opacity: 0;
      }
      .img2 {
        width: 8.57rem;
        height: 8.57rem;
        opacity: 1;
        padding: 4.26rem 5.71rem;
        display: flex;
        flex-direction: column;
        span {
          font-size: 2rem;
          position: absolute;
          bottom: 4.29rem;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
