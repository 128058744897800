<template>
  <div class="home-service" id="Power">
    <div class="home-service-top">
      <div class="home-service-top" data-aos="zoom-out">
        <div class="title-en" style="text-transform: uppercase">industry analysis</div>
        <div class="title-zh">
          <span>行业分析</span>
        </div>
      </div>
      <div class="desc">
        <div class="tag" :class="{ ac: ac === '工具不适' }" data-aos="fade-right" @click="ac = '工具不适'">工具不适</div>
        <div class="tag" :class="{ ac: ac === '成本递增' }" data-aos="fade-right" @click="ac = '成本递增'">成本递增</div>
        <div class="tag" :class="{ ac: ac === '自营困局' }" data-aos="fade-left" @click="ac = '自营困局'">自营困局</div>
        <div class="tag" :class="{ ac: ac === '加盟利弊' }" data-aos="fade-left" @click="ac = '加盟利弊'">加盟利弊</div>
      </div>
    </div>
    <template v-for="a in list" :key="a.t2">
      <div v-if="a.t2 === ac" class="home-service-card">
        <div class="left">
          <img :src="a.img" data-aos="fade-right" />
        </div>
        <div class="right" data-aos="fade-left">
          <div class="en">{{ a.t1 }}</div>
          <div class="zh">{{ a.t2 }}</div>
          <p style="font-size: 1.8rem">{{ a.text }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HomePower',
  data() {
    return {
      ac: '工具不适',
      list: [
        {
          img: require('../assets/win/win-tool.png'),
          t1: 'INAPPROPRIATE SOFTWARE',
          t2: '工具不适',
          text: '资源量达不到，资源面达不到，资源维护跟不上，没有树立品牌价值的意识与条件，更没有足够预算提高服务水平。',
        },
        {
          img: require('../assets/win/win-cost.png'),
          t1: 'INCREASING COSTS',
          t2: '成本递增',
          text: '行业鱼目混珠，恶性竞争，致使人力成本逐年攀升，房租上涨、成交周期变长，正所谓循规蹈矩是死，恶性竞争也是死，大多企业举步维艰。',
        },
        {
          img: require('../assets/win/win-dilemma.png'),
          t1: 'PROPRIETARY DILEMMA',
          t2: '自营困局',
          text: '管理和营销工具分离，地域性需求差异大，软件教学不深入，导致各种使用不称手，操作繁杂、更新不及时，没有完善的反馈改善机制，相对独立兼容性及包容性不够，不能协调工作，定制费用高，或者自身品牌不能得到推广。',
        },
        {
          img: require('../assets/win/win-proCon.png'),
          t1: 'JOINING THE PROS AND CONS',
          t2: '加盟利弊',
          text: '这两年加盟平台盛行，但弊端也逐步显现，不仅费用高昂，制度还苛刻且繁多，更致命的束缚了加盟商的发展，使其公司渐渐失去了价值，员工转而对平台的依赖越发明显。从短期来看，有些加盟商获利了，但绝大多反而增加运营成本；长期来看，更明显的是品牌被弱化，大多数甚至已被同化，误认为平台是自己的家，已经完全没有自己品牌价值，试想一个没有名字的人很快被淡忘，记住：借来的光，随时都可能要还。俗话说，自己都靠不住，何况别人，命运不能掌握在别人手里，必须自己握紧。',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home-service {
  background-image: url('../assets/gray-bg.png');
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem 0;
  &-top {
    text-align: center;
    .title-en {
      font-size: 4rem;
      @media (min-width: 800px) {
        font-size: 5rem;
      }
      font-weight: bold;
    }
    .title-zh {
      margin-top: 1rem;
      span {
        font-size: 3rem;
        @media (min-width: 800px) {
          font-size: 4rem;
        }
        font-weight: bold;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          background-color: currentColor;
          width: 7.143rem;
          height: 0.57rem;
          top: 50%;
          transform: translateY(-50%);
          left: -8.57rem;
        }
        &:after {
          content: '';
          position: absolute;
          background-color: currentColor;
          width: 7.143rem;
          height: 0.57rem;
          top: 50%;
          transform: translateY(-50%);
          right: -8.57rem;
        }
      }
    }
    .tag {
      cursor: default;
      &:hover {
        color: black;
        opacity: 1;
      }
    }
    .desc {
      display: flex;
      justify-content: space-evenly;
      width: 95vw;
      @media (min-width: 800px) {
        width: 106rem;
      }
      font-size: 2rem;
      color: #808080;
      padding-top: 4rem;
      padding-bottom: 4rem;
      border-bottom: 2px solid #cccccc;
      .ac {
        color: #f08b1a;
        &:after {
          content: '';
          position: absolute;
          left: -1em;
          right: -1em;
          bottom: -2.1em;
          height: 2px;
          background-color: #f08b1a;
        }
      }
    }
  }

  &-card {
    width: 95vw;
    @media (min-width: 800px) {
      width: 106rem;
    }
    padding-top: 8.4rem;
    display: flex;
    justify-content: space-between;
    .left {
      display: grid;
      place-content: center;
      width: 30rem;
      height: 24rem;
      @media (min-width: 800px) {
        width: 64.57rem;
        height: 50.14rem;
      }
      background: white;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 6px;
      img {
        width: 22rem;
        height: 16rem;
        @media (min-width: 800px) {
          width: 48.57rem;
          height: 34.14rem;
        }
      }
    }

    .right {
      flex: 1;
      padding-left: 2rem;
      @media (min-width: 800px) {
        padding: 4rem;
      }

      .en {
        font-size: 2rem;
        color: #a6a6a6;
      }

      .zh {
        font-size: 4rem;
        @media (min-width: 800px) {
          font-size: 4rem;
        }
        font-weight: bold;
        color: #282828;
        margin-bottom: 2rem;
      }

      .line {
        font-size: 2rem;
        font-weight: 400;
        color: #282828;
        margin-bottom: 1rem;
      }
      p {
        color: #a6a6a6;
      }
    }
  }
}
</style>
